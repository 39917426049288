'use client'

// React Imports
import {useEffect, useRef, useState} from 'react'

// Next Imports
import Link from 'next/link'
import {usePathname, useParams, useRouter} from 'next/navigation'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'

// Type Imports
import type {Locale} from '@configs/i18n'

// Hook Imports
import {useSettings} from '@core/hooks/useSettings'
import {getDictionary} from "@/utils/getDictionary";
import Tooltip from "@mui/material/Tooltip";
import {useSessionContext} from "@layouts/SessionContext";
import axios from "axios";
import {string} from "valibot";
import {signOut} from "next-auth/react";
import {getLocalizedUrl} from "@/utils/i18n";

type LanguageDataType = {
    langCode: Locale
    langName: string
}

const getLocalePath = (pathName: string, locale: string) => {
    if (!pathName) return '/'
    const segments = pathName.split('/')

    segments[1] = locale

    return segments.join('/')
}

// Vars
const languageData: LanguageDataType[] = [
    {
        langCode: 'it',
        langName: 'Italian'
    }, {
        langCode: 'en',
        langName: 'English'
    },
]

const UserState = () => {
    const session: any = useSessionContext();
    const sessionContext: any = session.sessionContext.session;

    if(sessionContext.user.role < 70) return null

    const {lang: locale, id}: any = useParams()
    const [t, setTranslations]: any = useState({})

    const [fornitore, setFornitore] = useState({} as any)
    const [userInfo, setUserInfo] = useState({
        icon: 'mdi--user-alert',
        color: 'red',
        text: ''
    })
    const router = useRouter();

    useEffect(() => {
        getDictionary(locale as Locale).then(dictionary => {
            setTranslations(dictionary)
        })
    }, []);

    useEffect(() => {
        if (t[locale.toString()]) {
            let options = {
                headers: {
                    "Content-Type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${sessionContext.tokenApi}`,
                    'Accept-Language': locale,
                },
                credentials: "same-origin",
                mode: "cors",
            }

            axios.get(`${process.env.NEXT_PUBLIC_API_URL}/fornitore/${id}`, options)
                .then((response: any) => {
                    let stato = response.data.data.fornitore.stato
                    setFornitore(response.data.data)

                    if (stato === 1) {
                        setUserInfo({
                            icon: "mdi--user-alert",
                            color: '#D80707',
                            text: translate('IN_ATTESA', "statoFornitore")
                        })
                    } else if (stato === 2) {
                        setUserInfo({
                            icon: "mdi--user-check",
                            color: '#17E41F',
                            text: translate('ATTIVO', "statoFornitore")
                        })
                    } else if (stato === 3) {
                        setUserInfo({
                            icon: "mdi--user-clock",
                            color: '#FFC700',
                            text: translate('IN_SCADENZA', "statoFornitore")
                        })
                    } else if (stato === 4) {
                        setUserInfo({
                            icon: "mdi--user-alert",
                            color: '#D80707',
                            text: translate('SCADUTO', "statoFornitore")
                        })
                    } else if (stato === 5) {
                        setUserInfo({
                            icon: "mdi--user-alert",
                            color: '#D80707',
                            text: translate('SOSPESO', "statoFornitore")
                        })
                    }

                })
                .catch((err: any) => {
                    console.log(err)
                    if(err.response.data.errors[0].field === 'user'){
                        signOut({ redirect: false }).then(()=> {
                            localStorage.clear()
                            router.push(getLocalizedUrl('/login', locale as Locale))
                        })
                    }
                })
        }

    }, [t, sessionContext, session.refreshCond]);

    function translate(key: string, group: string = locale) {
        return t[group]?.[key] || key
    }

    return userInfo.color && (
        <>
            <Tooltip title={userInfo.text}>
                <i className={userInfo.icon}  style={{color: userInfo.color, marginRight: '5px', fontSize: '28px'}}/>
            </Tooltip>
        </>
    )
}

export default UserState
