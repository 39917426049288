'use client'
import React, {createContext, useContext, useState, useEffect} from 'react';
import {signOut, useSession} from "next-auth/react";
import {getLocalizedUrl} from '@/utils/i18n';
import {useParams, useRouter} from "next/navigation";
import type {Locale} from '@/configs/i18n'
import {checkPermissions} from "@/utils/roles";


// Creazione del contesto per la sessione
const SessionContext = createContext<any>(null);

// Hook per accedere al contesto
export const useSessionContext = () => useContext(SessionContext);


let refreshCond = 0

// Componente Provider per la sessione
export const SessionProvider = ({children}: any) => {
    const [session, setSession]: any = useState(null);
    const mySession: any = useSession();
    const {lang: locale}: any = useParams()
    const router = useRouter()

    const [refresh, setRefresh] = useState(false)


    const RefreshComponent = (number: number): any => {
        refreshCond = number
        setRefresh(!refresh)

        return 'Refresh Ok!'
    }

    const waitPermission = async (mySession: any) => {
        return await checkPermissions(mySession.user.permissions, mySession.user.role)
    }


    useEffect(() => {
        if (mySession.status === 'authenticated') {
            if (mySession?.data === null) {
                logOut()
            } else {
                if (!mySession?.data?.tokenApi) {
                    router.push(getLocalizedUrl('/auth', locale as Locale))
                }

                waitPermission(mySession.data).then((r: any) => {
                    let data = mySession.data
                    data.permissions = r.permessi_roles
                    setSession((prev: any) => ({
                        ...prev,
                        session: data
                    }))
                })
            }
        }
    }, [mySession.status]);

    async function logOut() {
        localStorage.clear()
        await signOut({redirect: false})

        router.push(getLocalizedUrl('/login', locale as Locale))
    }

    return mySession.status === 'authenticated' ? (
        <SessionContext.Provider value={{sessionContext: session, RefreshComponent, refreshCond}}>
            {children}
        </SessionContext.Provider>
    ) : null;
};